/* zh fonts */

@font-face {
  font-family: "Noto Sans";
  src: local("Noto Sans"),
    url("../fonts/noto_sans_tc/noto_sans_tc-black.otf") format("opentype"),
    url("../fonts/noto_sans_tc/noto_sans_tc-black.woff2") format("woff2"),
    url("../fonts/noto_sans_tc/noto_sans_tc-black.woff") format("woff");
  font-weight: 900;
}

/* 700, bold */
@font-face {
  font-family: "Noto Sans";
  src: local("Noto Sans"),
    url("../fonts/noto_sans_tc/noto_sans_tc-bold.otf") format("opentype"),
    url("../fonts/noto_sans_tc/noto_sans_tc-bold.woff2") format("woff2"),
    url("../fonts/noto_sans_tc/noto_sans_tc-bold.woff") format("woff");
  font-weight: 700;
}

/* 500, bolder */
@font-face {
  font-family: "Noto Sans";
  src: local("Noto Sans"),
    url("../fonts/noto_sans_tc/noto_sans_tc-medium.otf") format("opentype"),
    url("../fonts/noto_sans_tc/noto_sans_tc-medium.woff2") format("woff2"),
    url("../fonts/noto_sans_tc/noto_sans_tc-medium.woff") format("woff");
  font-weight: 500;
}

/* normal, 400 */
@font-face {
  font-family: "Noto Sans";
  src: local("Noto Sans"),
    url("../fonts/noto_sans_tc/noto_sans_tc-regular.otf") format("opentype"),
    url("../fonts/noto_sans_tc/noto_sans_tc-regular.woff2") format("woff2"),
    url("../fonts/noto_sans_tc/noto_sans_tc-regular.woff") format("woff");
  font-weight: 400;
}

@font-face {
  font-family: "Noto Sans";
  src: local("Noto Sans"),
    url("../fonts/noto_sans_tc/noto_sans_tc-thin.otf") format("opentype"),
    url("../fonts/noto_sans_tc/noto_sans_tc-thin.woff2") format("woff2"),
    url("../fonts/noto_sans_tc/noto_sans_tc-thin.woff") format("woff");
  font-weight: 100;
}

img {
  @extend .img-fluid;
}

.categorySection {
  position: fixed;
  z-index: 3;
  right: 0;
  left: 0;
  transition: 1s all;

  &.alignTop {
    transform: translateY(-200px);
  }

  .positionWrapper {
    max-width: 1297px;
    margin-left: auto;
    margin-right: auto;
    position: relative;

    .nav-pills {
      max-width: 145px;
      color: $gray-500;
      position: absolute;
      right: 40px;
      @include font-size(20px);
      line-height: 28px;

      li {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .nav-item:not(:first-child) {
        margin-top: 50px;
      }
    }

    .sub_links {
      @include font-size(16px);
      line-height: 19px;
    }
  }
}

.topInfoContainer {
  border-bottom: 1px solid $gray-300;
  margin-top: 20px;
  margin-bottom: 35px;
  padding-bottom: 15px;
  color: $gray-300;

  .targetImage {
    width: 100%;
  }

  .metaInfo {
    span {
      font-size: px-to-rem(20);
      line-height: 29px;

      &:not(:last-child)::after {
        content: ".";
        width: 34px;
        padding-right: 15px;
        padding-left: 15px;
      }
    }
  }
}

.articleSocialMediaWidget {
  & {
    padding: 10px 25px;
    border-top: 1px solid #eaeaea;
    border-bottom: 1px solid #eaeaea;

    .shareLinksContainer {
      a {
        width: 22px;
        height: 22px;
        padding: 5px;

        svg {
          width: 100%;
        }

        &.shareBtn[data-url] {
          cursor: pointer;

          &:hover {
            color: #fff;
            background-color: #23272b;
            border-color: #1d2124;
          }
        }
      }

      // .badgeInfo {
      //   vertical-align: text-bottom;
      //   font-weight: normal;
      //   height: 22px;
      //   background-color: #ED4956;
      //   color: white;
      //   line-height: initial;
      //   padding: 0px 8px;

      //   .eyeIcon {
      //     width: 20px;
      //   }
      // }
    }
  }
}

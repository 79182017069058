.aboutSection {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 96px 0px 60px;

  .topImg {
    margin-bottom: 28px;
  }

  article {
    text-align: center;
    margin-bottom: 54px;
  }
}

.link_image_carousel_section_block {
  .btn_outer_wrapper {
    margin-left: calc(0rem - $grid-gutter-width / 2);
    margin-right: calc(0rem - $grid-gutter-width / 2);
  }

  .btn_wrapper {
    height: 462px;
    padding: 10px;
    width: 100%;

    @media (min-width: map-get($grid-breakpoints, md)) {
      width: 50%;
    }

    img {
      object-fit: cover;
    }
  }
}

.productBricksSection {
  margin-top: 70px;

  .title_decoration_img {
    max-width: 70px;
  }

  .productsBrick {
    padding-left: calc($grid-gutter-width / 2);
    padding-right: calc($grid-gutter-width / 2);
  }

  .full_height {
    height: 500px;
  }

  .half_height {
    height: 250px;
  }

  .backgroundHolder {
    // background-image: url("https://via.placeholder.com/500/BDC9B6");
    background-color: #bdc9b6;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
  }

  .cover {
    background-color: rgba(0, 0, 0, 0.5);
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    padding: 15px;

    button {
      position: relative;
      z-index: 2;
      margin: 15px;
      width: 50px;
      height: 50px;
    }

    transition: 0.5s all;
    opacity: 0;
  }

  .backgroundHolder:hover .cover {
    opacity: 1;
  }
}

.hero_text_section {
  margin-top: 70px;
  margin-bottom: 70px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 180px;
  padding-bottom: 180px;

  h3 {
    @include font-size(30px);
    line-height: 45px;
    letter-spacing: 1.13px;
    margin-bottom: 20px;
  }
}

.blogs_section_block {
  &--bg {
    background-color: $primary;

    @include media-breakpoint-up(sm) {
      background-color: transparent;
    }
  }

  .heading {
    padding-top: 32px;
    padding-block-end: 40px;
  }

  h3 {
    @include font-size(30px);
    line-height: 45px;
    letter-spacing: 2px;
    margin-block-end: 7px;
  }

  .ig_btn {
    margin-top: 11px;

    svg {
      width: 32px;
      margin-right: 9.5px;
    }
  }

  .blog_link {
    width: 243px;

    @include media-breakpoint-up(sm) {
      width: unset;
    }

    height: 243px;
    background-image: url("https://via.placeholder.com/300/c499c745");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .cover {
    background-color: rgba(188, 121, 104, 0.5);
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    transition: 0.5s all;
    opacity: 0;
  }

  .blog_link:hover .cover {
    opacity: 1;
  }

  border-bottom: 57px solid $orange-400;
}

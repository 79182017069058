& {
  margin-bottom: 10px;
}

.topCover {
  position: relative;
  height: 200px;

  @media (min-width: map-get($grid-breakpoints, sm)) {
    height: 260px;
  }

  .img_animation-opacity {
    top: 0;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition: 0.5s all;
  }

  &:hover {
    .img_animation-opacity {
      opacity: 1;
    }
  }

  img {
    object-fit: contain;
    height: 100%;
  }

  .favoriteActionCover {
    position: absolute;
    right: 10px;
    top: 10px;

    button {
      background-color: rgba(255, 255, 255, 0.7);
      width: 40px;
      height: 40px;
      color: $orange-400;
    }
  }

  .overImgCover {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &.soldOut {
      background-color: rgba(0, 0, 0, 0.5);
      line-height: 36px;
    }

    &.hint {
      .tagHolder {
        left: 30px;
        right: 30px;
        background-color: rgba(255, 255, 255, 0.7);
        color: $orange-400;
        width: 80%;
        text-align: center;
        transition: all 0.5s;
        padding-top: 6px;
        padding-bottom: 6px;
        opacity: 0;

        &.show {
          opacity: 1;
        }
      }
    }
  }
}

.card-body {
  line-height: 24px;
}

.favoriteBtn {
  z-index: 2;
}

.cartBtn {
  z-index: 2;

  &.disabled {
    background-color: $gray-200;
    border-color: $gray-200;
  }

  .cartIconWrapper {
    width: 32px;
  }
}

.card-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  /* number of lines to show */
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  min-height: 48px;
}

& {
  background-color: $gray-800;
  @include font-size(14px);
  color: white;

  .mainContainer {
    padding-top: 50px;
    padding-bottom: 64px;

    .row > div {
      padding-top: 16px;
    }

    .list-group .list-group-item {
      background-color: transparent;
      color: white;
    }
  }
}

.navbar-brand {
  width: 150px;
  height: 150px;
  margin-right: 0;
}

.questionLinks {
  a {
    margin-top: 0.25rem;
    border-radius: 0;
    padding: 0px 0.35rem 0 0.35rem;
    border-right: 1px solid white;

    &:last-child {
      border-right: 0;
    }

    &:first-child {
      padding-left: 0;
    }
  }
}

.socialMediaContainer {
  a {
    width: 40px;
    height: 40px;
    padding: 8px;

    [data-icon="facebook-f"] {
      height: 24px;
    }

    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
}

.copyrightContainer {
  background-color: $gray-700;
  @include font-size(16px);
  line-height: 24px;
}

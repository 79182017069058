.subpageWrapper {
  min-height: calc(100vh - 140px);
  position: relative;

  @media (min-width: map-get($grid-breakpoints, sm)) {
    padding-top: 25px;
  }

  @media (min-width: map-get($grid-breakpoints, lg)) {
    padding-top: 80px;
  }

  .backgroundHolder {
    background-color: $orange-400;
    top: 0;
    height: 80vh;
    width: 100%;
    max-height: 930px;
    z-index: -1;
    position: absolute;
  }

  .mainContainer {
    max-width: 1297px;
    background-color: white;
    margin-right: auto;
    margin-left: auto;
    padding: 50px 0px;

    @media (min-width: map-get($grid-breakpoints, xl)) {
      padding: 70px 0px;
    }
  }
}

.containerWrapper {
  @media (min-width: map-get($container-max-widths, lg)) {
    padding-left: calc((1116px - 1000px) / 2);
    padding-right: calc((1116px - 1000px) / 2);
  }
}

.breadcrumbContainer {
  margin-bottom: 30px;
}

.headingContainer {
  h1 {
    margin-bottom: 1rem;
    letter-spacing: -0.31px;
    line-height: 89px;
  }

  p {
    line-height: 24px;
    letter-spacing: -0.08px;
  }
}

.categoryFilter {
  .nav-pills {
    margin-bottom: 40px;

    .nav-item {
      @include font-size(20px);
      letter-spacing: -0.1px;
      position: relative;

      &:first-child .nav-link {
        padding-left: 0;
      }

      &:not(:first-child) {
        &::after {
          content: " ";
          color: $gray-300;
          position: absolute;
          left: 0;
          bottom: 0.5rem;
          top: 0.5rem;
          width: 1px;
          background-color: $gray-300;
        }
      }

      .nav-link {
        color: $gray-300;

        &.active {
          color: black;
        }
      }
    }
  }
}

.listSection {
  .newsCard {
    hr {
      max-width: 120px;
    }

    .card-text {
      color: $gray-600;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
    }
  }
}

.cart_products_section {
  .img_cell {
    width: 110px;
  }

  .quantity_cell {
    width: 100px;
  }

  .cover {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .trash_btn {
    width: 30px;
    height: 30px;
  }

  .product_uuid_cell {
    word-break: break-all;

    @media (min-width: map-get($grid-breakpoints, md)) {
      width: 5%;
      min-width: 70px;
    }
  }

  .product_info_cell {
    @media (min-width: map-get($grid-breakpoints, md)) {
      width: 50%;

      .product_name {
        width: 60%;
      }

      .product_spec {
        width: 40%;
      }
    }
  }

  .image_container {
    width: 75px;

    img {
      width: 75px;
      height: 75px;
      object-fit: cover;
    }

    @media (min-width: map-get($grid-breakpoints, sm)) {
      width: 120px;

      img {
        width: 120px;
        height: 120px;
      }
    }
  }

  .action_container {
    width: 25%;

    @media (min-width: map-get($grid-breakpoints, md)) {
      width: 45%;

      .cell {
        width: 100%;
      }
    }
  }
}

.offer_list_wrapper {
  padding-top: $input-padding-y;
  padding-bottom: $input-padding-y;
  padding-left: $input-padding-x;
  padding-right: $input-padding-x;
  border-radius: $input-border-radius;
  border: $input-border-width solid $input-border-color;

  span {
    display: inline-block;
    transform: scale(0.85);
    transform-origin: left;
    opacity: $form-floating-label-opacity;
  }
}

.summary_section_block {
  .border-top {
    border-top: 1px solid $gray-600;
  }
}

.shipping_area {
  background-color: $gray-100;
}

.title-min_w-50 {
  display: inline-block;
  min-width: 50px;
}

.sortContainer {
  a {
    position: relative;
    color: $gray-600;
    padding-top: 10px;
    padding-bottom: 10px;

    &:not(:last-child) {
      padding-right: 10px;
      margin-right: 10px;

      &::before {
        content: " ";
        top: 15px;
        bottom: 15px;
        width: 1px;
        background-color: $gray-600;
        position: absolute;
        right: 0;
        color: $gray-600;
      }
    }
  }
}

.categoryFilter {
  a {
    color: $gray-500;
  }
}

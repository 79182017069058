.headerTopBar {
  background-color: $gray-800;
  padding-top: 10px;
  padding-bottom: 10px;

  .btn {
    width: 30px;
    height: 30px;
    padding: 0px;

    svg {
      width: 100%;

      &[data-icon="facebook-f"] {
        height: 100%;
      }
    }
  }

  .socialMediaContainer {
    .btn:not(:first-child) {
      margin-left: 30px;
    }
  }

  .utilsContainer {
    .btn:not(:last-child) {
      margin-left: 30px;
    }

    #searchCollapse {
      min-width: 250px;
    }
  }

  .cart-wrapper--hover-open {
    &.open,
    &:hover {
      .dropdown-menu-cart {
        display: block;
        position: absolute;
        inset: 0px 0px auto auto;
        margin: 0px;
        transform: translate3d(0px, 30px, 0px);
      }
    }
  }
}

.dropdown-menu-cart {
  width: 263px;
  color: $gray-700;

  .dropdown_max-height {
    max-height: 80vh;
    overflow-y: scroll;
  }

  .product-text--oneline {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.navbar-toggler {
  width: 30px;
}

.menuBar {
  height: 64px;
  border-bottom: 1px solid $gray-500;

  @media (min-width: map-get($grid-breakpoints, lg)) {
    margin-top: 50px;
    height: 90px;
  }

  .btn_menu_default {
    width: 50px;
    border-radius: 0.25rem;

    svg[data-icon="times"] {
      display: none;
    }

    &[aria-expanded="true"] {
      svg[data-icon="times"] {
        display: block;
      }

      svg[data-icon="grip-lines"] {
        display: none;
      }
    }
  }

  .category_link {
    @include font-size(16px);
  }

  .dropdown-hover-open:hover {
    > * {
      display: block;
    }
  }
}

.header_menu_type_default {
  position: absolute;
  z-index: 4;
  left: 0;
  right: 0;
}

.offcanvas_mobile_menu {
  .btn_social {
    width: 25px;
    height: 25px;

    [data-icon="facebook-f"] {
      height: 100%;
    }
  }

  .btn_tool {
    width: 22px;
    height: 22px;
  }
}

.breadcrumbContainer {
  margin-top: 24px;
  margin-bottom: 33px;
}

.main-image {
  height: 380px;
  object-fit: cover;
}

.imgItem_subimage-border {
  border: 2px solid white;

  &.active {
    border: 2px solid #bc7968;
  }
}

.subImagesContainer {
  .slick-arrow {
    z-index: 2;
    transition: 0.5s all;
    background: black;
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }

    height: calc(100% - 4px); // alignment

    &.slick-prev {
      left: 0;

      &::before {
        content: "◀";
        color: white;
        background-color: black;
      }
    }

    &.slick-next {
      right: 0;

      &::before {
        content: "▶";
        color: white;
        background-color: black;
      }
    }
  }

  .slick-track {
    margin-left: 0;
  }
}

.sub-image {
  cursor: pointer;
  object-fit: cover;
  height: 150px;

  @media (min-width: map-get($grid-breakpoints, lg)) {
    height: 85px;
  }
}

[data-bs-target] {
  cursor: pointer;
}

.carousel_inner--height {
  min-height: 498px;
}

h1 {
  @include font-size(24px);
  letter-spacing: -0.13px;
  line-height: 36px;
  margin-bottom: 19px;
}

.category_link {
  margin-top: 19px;
}

.short_descrption {
  min-height: 122px;
  color: $gray-600;
  line-height: 19px;
  letter-spacing: -0.08px;
}

.price_text {
  @include font-size(30px);
  font-weight: 500;
  letter-spacing: -0.12px;
  line-height: 29px;
  margin-bottom: 21px;
}

.add_to_fav {
  color: $gray-600;

  @include font-size(12px);

  svg {
    margin-right: 8px;
    max-width: 25px;
    max-height: 25px;
  }
}

$wrapper_sm_width: 328px;

.cart_buttons_wrapper {
  button {
    width: 120px;

    @media (min-width: map-get($grid-breakpoints, sm)) {
      width: calc($wrapper_sm_width / 2 - (8.5px / 2));
    }
  }
}

.badge_w_add_wrapper,
.number_wrapper,
.selection_wrapper,
.cart_buttons_wrapper {
  @media (min-width: map-get($grid-breakpoints, sm)) {
    width: $wrapper_sm_width;
  }
}

.badge_w_add_wrapper {
  margin-bottom: 24px;
}

.number_wrapper,
.selection_wrapper {
  margin-bottom: 17px;
}

/* details */
$pills-px: 25px;

.nav-pills,
.tab-content {
  @media (min-width: map-get($grid-breakpoints, lg)) {
    padding-left: 100px;
    padding-right: 100px;
  }
}

.nav-pills {
  margin-top: 25px;
  padding-bottom: 14.5px;
  margin-bottom: 25px;
  border-bottom: 1px solid $orange-400;

  width: 100%;

  .nav-item {
    width: 50%;
  }

  @media (min-width: map-get($grid-breakpoints, sm)) {
    width: unset;

    .nav-item {
      width: unset;
    }
  }

  .nav-link {
    width: 100%;
    @include font-size(14px);
    line-height: 20px;
    letter-spacing: -0.07px;
    padding-left: $pills-px;
    padding-right: $pills-px;

    @media (min-width: map-get($grid-breakpoints, sm)) {
      @include font-size(24px);
      line-height: 36px;
      letter-spacing: -0.12px;
    }
  }

  .nav-item:not(:last-child) .nav-link {
    border-right: 1px solid $orange-400;
  }
}

.tab-content {
  padding-top: 25px;
  padding-bottom: 25px;

  img {
    max-width: 100%;
    height: auto;
  }
}

.products_list_section_block {
  .heading {
    height: 86px;
    background-color: $orange-300;
    margin-bottom: 48px;

    * {
      @include font-size(21px);
      line-height: 31px;
      letter-spacing: 2.1px;
    }
  }

  .bottom_link {
    height: 86px;
    background-color: $orange-300;
    margin-top: 48px;
  }
}

.btn-gray-800 {
  @include button-variant($gray-800, $gray-800);
}

.btn-light-white {
  @include button-variant(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7));
}

.btn-brand-teal {
  @include button-variant($teal-600, $teal-600);
}

.btn-outline-primary:hover,
.btn-primary,
.btn-primary:hover,
.btn-primary:focus-within,
.btn-primary:focus {
  color: white;
}

.bg-gray-750 {
  background-color: $gray-750;
}

.bg-gray-100 {
  background-color: $gray-100;
}

.h7 {
  @include font-size(16px);
  line-height: 24px;
}

.pagination {
  @include font-size(14px);
  line-height: 18px;

  .active {
    border-bottom: 1px solid $orange-400;
  }
}

/* general style */
.sectionHeadingBottomDecoration {
  color: $orange-400;
  text-align: center;
  position: relative;
  margin-bottom: 16px;

  &:after {
    content: " ";
    position: relative;
    width: 50px;
    height: 6px;
    border-radius: 3px;
    background-color: $orange-400;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 16px;
  }
}

/* member badge */
.member_badge {
  font-weight: $font-weight-bold;
  @include font-size(14px);
  padding-left: 15px;
  padding-right: 15px;
  background-color: $gray-600;

  &.bg_orange {
    background-color: #d77d08;
  }

  &.bg_red {
    background-color: #b12525;
  }

  &.bg_blue {
    background-color: #2581c6;
  }

  &.bg_green {
    background-color: #5b7118;
  }
}

.obj-fit--cover {
  object-fit: cover;
}

.obj-fit--contain {
  object-fit: contain;
}

.main_content--min-h {
  min-height: calc(100vh - 140px - 361.19px);
}

.min-vh-wo-header-footer {
  min-height: calc(100vh - 90px - 50px - 200px);
}

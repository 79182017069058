.border_wrapper {
  padding: 20px 15px;

  @media (min-width: map-get($grid-breakpoints, sm)) {
    border: 0;
  }
}

.highlight_red {
  .border_wrapper {
    border: 1px solid $danger;
    background-color: $red-100;
  }

  .dashed_decoration {
    background: repeating-linear-gradient(
      90deg,
      $danger,
      $danger 10px,
      transparent 10px,
      transparent 20px
    );
  }
}

.verified_icon {
  width: 15px;
}

.birthday_coin_text {
  @include font-size(22px);

  @media (min-width: map-get($grid-breakpoints, sm)) {
    @include font-size(14px);
  }
}

.fb_color {
  color: #1a4ba9;
}

.ig_color {
  color: #3642df;
}

.line_color {
  color: #399f1e;
}

.twitter_color {
  color: #1da1f2;
}

.parallelogram_shape {
  position: relative;
  height: 33px;
  width: 52px;
  padding: 4px;

  &::before {
    position: absolute;
    z-index: -1;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: skew(-30deg);
  }

  &.fb_bk::before {
    background: linear-gradient(180deg, #1d4fac 0%, #002f89 100%);
  }

  &.ig_bk::before {
    background: linear-gradient(
      45deg,
      #f6ba00,
      #fcaf45,
      #f77737,
      #f56040,
      #fd1d1d,
      #e1306c,
      #c13584,
      #833ab4,
      #5851db,
      #405de6
    );
  }

  &.line_bk::before {
    background: linear-gradient(45deg, #1c8700, #46aa2b);
  }

  &.tw_bk::before {
    background: linear-gradient(45deg, #1da1f2, #81c4ed);
  }

  > * {
    height: 100%;
  }
}

$bg_holder_color: $gray-200;
$header_decoration_color: $teal-600;
$blog_item_title_color: $green-500;

@mixin theme_color(
  $bg_holder_color,
  $header_decoration_colo,
  $blog_item_title_color
) {
  $bg_holder_color: $bg_holder_color;
  $header_decoration_color: $header_decoration_color;
  $blog_item_title_color: $blog_item_title_color;
}

/* .theme_a  */

.theme_b {
  @extend .subpageWrapper;

  // @include theme_color($teal-600, $orange-400, $orange-400); TODO: fix varaiable assign glitch - scss
  .backgroundHolder {
    background-color: $teal-600;
  }

  h1:before {
    background-color: $orange-400;
  }

  .list_container .categoryText {
    color: $orange-400;
  }
}

.backgroundHolder {
  background-color: $bg_holder_color;
}

.heading_container {
  margin-bottom: 92px;
}

.card-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

h1 {
  letter-spacing: -0.25px;
  line-height: 56px;
  position: relative;

  &::before {
    content: " ";
    position: relative;
    top: -11px;
    left: 0;
    width: 40%;
    height: 5px;
    background-color: $header_decoration_color;
    display: block;
    border-radius: 3px;
    max-width: 80px;
  }
}

.dropdown_wrapper {
  @media (min-width: map-get($grid-breakpoints, lg)) {
    margin-left: 38.5px;
  }

  height: 100%;
  display: flex;
  width: 100%;
  border-bottom: 1px solid $gray-400;
}

.dropdown {
  margin-left: auto;
  margin-bottom: 10px;
}

.categoryText {
  color: $blog_item_title_color;
  @include font-size(14px);
}

.hightlightCard {
  @media (min-width: map-get($grid-breakpoints, md)) {
    .topImageContainer {
      width: 50%;
    }

    .bodyWrapper {
      width: 50%;
    }
  }
}

.top_cover {
  height: 230px;

  img {
    height: 100%;
    object-fit: cover;
  }

  .cover {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s all;

    &.soldOut {
      background-color: rgba(0, 0, 0, 0.5);
      line-height: 36px;
      opacity: 1;
    }
  }

  &:hover .cover {
    opacity: 1;
  }

  button {
    width: 50px;
    height: 50px;
    z-index: 2;

    &.disabled {
      background-color: $gray-200;
      border-color: $gray-200;
    }
  }
}

.card-body {
  @include font-size(12);
  line-height: 18px;
  letter-spacing: 1.2px;
}

.card-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  /* number of lines to show */
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  min-height: 48px;
}

.favorite_action_toast_position {
  top: 20%;
  right: 10px;
  left: 10px;
  z-index: 3;
}

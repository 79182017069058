img {
  @extend .img-fluid;
}

.categorySection {
  position: fixed;
  z-index: 3;
  right: 0;
  left: 0;
  transition: 1s all;

  &.alignTop {
    transform: translateY(-200px);
  }

  .positionWrapper {
    max-width: 1297px;
    margin-left: auto;
    margin-right: auto;
    position: relative;

    .nav-pills {
      position: absolute;
      right: 40px;
      @include font-size(20px);
      line-height: 48px;
    }
  }
}

.metaInfo {
  padding: 4px;
  border-top: 1px solid $gray-400;
  border-bottom: 1px solid $gray-400;
  color: $gray-300;
  margin-bottom: 30px;

  span {
    font-size: px-to-rem(20);
    line-height: 29px;

    &:not(:last-child)::after {
      content: ".";
      width: 34px;
      padding-right: 15px;
      padding-left: 15px;
    }
  }
}

.articleSocialMediaWidget {
  & {
    padding: 10px 25px;
    border-top: 1px solid #eaeaea;
    border-bottom: 1px solid #eaeaea;

    .shareLinksContainer {
      a {
        width: 22px;
        height: 22px;
        padding: 5px;

        svg {
          width: 100%;
        }

        &.shareBtn[data-url] {
          cursor: pointer;

          &:hover {
            color: #fff;
            background-color: #23272b;
            border-color: #1d2124;
          }
        }
      }
    }
  }
}

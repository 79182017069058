.step_item {
  width: calc(100% / 3);
  min-width: 250px;
}

.number {
  @include font-size(21px);
  line-height: 32px;
  letter-spacing: -0.11px;
}

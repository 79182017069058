.searchInput {
  border-color: $gray-600;
  border-radius: 25px;
  @include font-size(14px);
  padding-right: 30px;

  &::placeholder {
    color: $gray-200;
  }
}

.searchBtn {
  width: 30px;
  height: 30px;
  padding: 5px;
  position: absolute;
  right: 0;
}

.nav-tabs {
  .nav-link {
    @include font-size(20px);
    line-height: 29px;
    letter-spacing: -0.1px;
    padding-top: 25px;
    padding-bottom: 25px;
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;

    &.active {
      color: black;

      &::before {
        content: " ";
        position: absolute;
        background-color: black;
        bottom: 20px;
        height: 1px;
        left: 35%;
        right: 35%;
      }
    }
  }
}

.tab-content {
  border-left: 1px solid $gray-250;
  border-bottom: 1px solid $gray-250;
  border-right: 1px solid $gray-250;
  border-radius: $nav-tabs-border-radius;
  color: $gray-700;

  @media (min-width: map-get($grid-breakpoints, lg)) {
    padding: 45px 50px 42px;
  }
}

.connect_social_media {
  .btn {
    width: 40px;
    height: 40px;
  }
}

@use "sass:math";
/* widget */
@import "../widgets/font-face";
@import "../widgets/utils";

@import "custom_bootstrap_vars";
@import "bootstrap";

/* slick variable */
$slick-font-path: "../fonts/vendor-slick/";
$slick-loader-path: "../images/vendor-slick/";
$slick-arrow-color: $gray-600;
$slick-dot-color: $orange-300;
$slick-dot-color-active: $primary;
$slick-dot-character: "\2014";
$slick-dot-size: 20px;
@import "slick-theme.scss";
@import "slick.scss";

/* PhotoSwipe vendor */
$pswp__assets-path: "../images/vendor-photoswipe/";
@import "default-skin.scss";
@import "main.scss";

/* fontaweseom */
// $fa-font-path: "../fonts/vendor-fontawesome";
// @import "fontawesome";
// @import "solid";
// @import "regular";
// @import "brands";

// project specific CSS goes here
/* custom class */
@import "widgets/custom_class";

/* default */
body {
  line-height: calc(28 / 20);
}

// parts //
.header {
  @import "layout/header";
}

.footer {
  @import "./layout/footer";
}

.breadcrumb {
  @import "./layout/breadcrumb";
}

.product_card_type_a {
  @import "./widgets/product_card_type_a";
}

.product_card_type_b {
  @import "./widgets/product_card_type_b";
}

.product_offcanvas {
  .img_height_width {
    height: 155px;
    width: 230px;
  }
  [data-btn-add-to-cart]:disabled {
    color: white;
    background-color: $gray-800;
    border-color: $gray-800;
  }
}

.sidebar_search_widget {
  @import "./widgets/sidebar_search_widget";
}

@import "./layout/subpage";

/* pages */
.home {
  @import "./pages/home";
}

.about {
  @import "./pages/about";
}

.news {
  &.newsList {
    @import "./pages/newsList";
  }

  &.newsDetails {
    @import "./pages/newsDetails";

    .headingSection,
    .mainSection,
    .bottomSection {
      .containerWrapper {
        @media (min-width: map-get($container-max-widths, xl)) {
          padding-left: calc((1116px - 800px) / 2);
          padding-right: calc((1116px - 800px) / 2);
        }
      }
    }
  }
  .tooltip {
    width: max-content;
  }
}

.blog {
  .breadcrumbContainer {
    margin-bottom: 50px;
  }

  &.blogList {
    @import "./pages/blogList";
  }

  &.blogDetails {
    @import "./pages/blogDetails";

    .breadcrumbContainer,
    .main_container,
    .bottom_navigation {
      .containerWrapper {
        @media (min-width: map-get($container-max-widths, xl)) {
          padding-left: calc((1116px - 800px) / 2);
          padding-right: calc((1116px - 800px) / 2);
        }
      }
    }
  }
}

.product {
  &.productList {
    @import "./pages/productList";
  }

  &.productDetails {
    @import "./pages/productDetails";
  }
}

.cart {
  color: $gray-700;
  @import "./pages/cart";
}

.search {
  .search_result {
    min-height: 35px;
  }
}

.contact,
.block-contact_us_section_block {
  .contact_info_section {
    background-color: $gray-200;
    @include font-size(21px);

    .containerWrapper * {
      margin-bottom: 1em;
    }
  }
}

.membership {
  color: $gray-700;

  .mw_600 {
    max-width: 600px;
  }

  .border_wrapper {
    padding: 2rem 1rem;
    border-radius: $nav-tabs-border-radius;
    border: 1px solid $gray-250;

    @media (min-width: map-get($grid-breakpoints, lg)) {
      padding: 45px 50px 42px;
    }
  }

  .dashed_decoration {
    opacity: 1;
    background: repeating-linear-gradient(
      90deg,
      $gray-250,
      $gray-250 10px,
      transparent 10px,
      transparent 20px
    );
  }

  &.login_signup {
    @import "./pages/login_signup";
  }

  &.phone_validation {
    .form-control {
      max-width: 50px;
    }
  }

  &.change_password {
    .icon_social {
      width: 30px;
      height: 30px;
    }
  }

  &.edit_member {
    @import "./pages/edit_member";
  }
}

.order {
  .title-min_w-50 {
    display: inline-block;
    min-width: 50px;
  }

  &.order_list {
    table tr th {
      min-width: 90px;
    }

    .cancel_note_text_area {
      height: 100px;
    }

    .selection_wrapper {
      width: 50%;
      min-width: 200px;
    }

    .modal_close_btn {
      position: absolute;
      right: 1rem;
    }
  }
}

.page_not_found {
  .main_content {
    height: 520px;
    position: relative;
  }

  .bg_holder {
    z-index: -1;
    opacity: 0.4;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: url("../images/projects/cing_yang/page_not_found_bg.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

/* general component */
.steps_section {
  @import "./widgets/order_steps";
}

.ad_modal {
  @media (min-width: map-get($grid-breakpoints, lg)) {
    max-width: 768px;
  }
}

.bg-dark-opacity-8 {
  background: rgba(0, 0, 0, 0.8);
}

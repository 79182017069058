img {
  max-width: 100%;
  height: auto;
}

.headerSection {
  padding: 40px 0 90px;

  .pageLogo {
    margin-bottom: 17px;
  }

  .h1 {
    line-height: 107px;
    letter-spacing: 5px;
    color: $gray-800;
  }

  .h6 {
    margin-top: 15px;
    color: $gray-500;
  }

  .h7 {
    color: $gray-500;
    margin-bottom: 8px;
  }
}

.jumbotronSection {
  margin-bottom: 60px;

  .container {
    @media (min-width: map-get($grid-breakpoints, xxl)) {
      max-width: 1195px;
    }

    .contentArea {
      background-color: #f1f3f4;

      @media (min-width: map-get($grid-breakpoints, sm)) {
        padding: 35px 20px 25px;
      }

      @media (min-width: map-get($grid-breakpoints, md)) {
        padding: 35px 50px 25px;
      }

      @media (min-width: map-get($grid-breakpoints, xl)) {
        padding: 35px 160px 25px;
      }

      p {
        color: $gray-600;
        text-align: center;
      }
    }
  }
}

.carouselSection {
  .reverseArea {
    height: 120px;
    background-color: $orange-900;

    .sectionHeadingBottomDecoration {
      color: white;
    }
  }

  .mainAreaCarousal {
    padding-top: 55px;
    padding-bottom: 55px;
  }

  .thumbItem {
    align-items: center;
    margin: auto;
    display: flex;
    max-width: 230px;
    height: 168px;
    position: relative;
    justify-content: center;

    .cover {
      left: 0;
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      bottom: 0;
      right: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      transition: 0.5s all;
    }

    .videoCover {
      @extend .cover;
      background-color: transparent;
      opacity: 1;
    }

    &:hover {
      .cover {
        opacity: 1;
      }

      .videoCover {
        opacity: 0;
      }
    }
  }
}

/* hero modal */
.heroModal {
  .slick-track {
    display: flex;
    align-items: center;
  }

  .modal_item {
    max-width: 700px;
    min-height: 25vh;

    @media (min-width: map-get($grid-breakpoints, lg)) {
      height: 400px;
      min-height: initial;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .modal-header {
    padding: 12px 50px;
  }

  .modal-body {
    padding: calc((530px - 463px) / 2) 50px;
  }

  .carousel_modal_title--lineHeight {
    line-height: 36px;
  }

  .carousel_modal_title--maxLine {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .carousel-control-next,
  .carousel-control-prev {
    width: 50px;
  }
}

/* end hero modal */

.mainSection {
  padding: 45px 0px 50px;
  background-color: $gray-100;

  .h3 {
    letter-spacing: -0.25px;
    line-height: 56px;
    margin-bottom: 35px;
  }

  p {
    @include font-size(20px);
    line-height: 28px;
  }
}

.thumbnailSection {
  padding: 55px 0px 70px;

  .modalTriggerBtn {
    height: 168px;
    box-shadow: 0 0 0 rgb(0 0 0 / 50%);
    transition: all 0.5s;

    .cover {
      @include font-size(20px);
      line-height: 28px;
      background-color: rgba(0, 0, 0, 0);
      transition: all 0.5s;
      opacity: 0;
      top: 0;
    }

    &:hover {
      box-shadow: 5px 5px 6px 0 rgb(0 0 0 / 50%);

      .cover {
        opacity: 1;
        background-color: rgba(0, 0, 0, 0.5);
      }
    }
  }

  .img_wrpper {
    height: 320px;
    min-width: 240px;
    max-width: 416px;

    .modal_image {
      height: 100%;
      object-fit: contain;
    }
  }

  .modal-title {
    line-height: 36px;
  }

  .modal-header,
  .modal-body {
    padding-left: 35px;
    padding-right: 35px;
  }

  .modal-header {
    padding-top: 13px;
    padding-bottom: 12px;
  }

  .modal-body {
    padding-top: 24px;
    padding-bottom: 24px;
  }
}

.certificationSection {
  h3 {
    color: $gray-500;
    letter-spacing: -0.5px;
    margin-bottom: 20px;
    position: relative;

    &::after {
      content: " ";
      position: absolute;
      width: 50px;
      height: 6px;
      border-radius: 3px;
      background-color: $gray-600;
      left: calc(100% + 15px);
      bottom: 6px;
    }
  }

  .itemOuterWrapper {
    margin-bottom: 20px;
    padding: 20px 0px 40px;
    border-bottom: 1px solid $teal-600;

    @media (min-width: map-get($grid-breakpoints, md)) {
      border-bottom: 0;
    }
  }

  .textWrapper {
    @media (min-width: map-get($grid-breakpoints, md)) {
      margin: 0px 20px;
      border-bottom: 1px solid $teal-600;
    }

    @media (min-width: map-get($grid-breakpoints, lg)) {
      margin: 0px 40px;
    }

    @media (min-width: map-get($grid-breakpoints, xl)) {
      margin: 0px 80px;
    }
  }

  .h7 {
    color: $gray-600;

    @media (min-width: map-get($grid-breakpoints, md)) {
      margin: 27px 0 25px;
    }
  }

  .description {
    color: $gray-500;
    @include font-size(14px);
    line-height: 20px;
    margin-bottom: 25px;
  }

  .imageItem {
    $img-width: 95px;
    $align-left: 12px;
    $holder-height: 150px;

    @media (min-width: map-get($grid-breakpoints, md)) {
      $img-width: 150px;
      $align-left: 18px;
      $holder-height: 230px;
    }

    min-width: $img-width + $align-left;
    width: $img-width + $align-left;

    position: relative;

    &::before {
      content: " ";
      background-color: $teal-600;
      position: absolute;
      height: $holder-height;
      left: 0;
      top: 0;
      bottom: 8px;
      right: 12px;
    }

    img {
      position: relative;
      width: $img-width;
      left: $align-left;
      top: 8px;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.5);
    }
  }
}

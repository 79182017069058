$font-family-base: "Noto Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
  Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1140.01px,
);

$gray-100: #f1f3f4;
$gray-200: #d8d8d8;
$gray-250: #cccccc;
$gray-300: #999999;
$gray-400: #979797;
$gray-500: #5f6368;
$gray-600: #666666;
$gray-700: #4a4a4a;
$gray-750: #a3a3a3;
$gray-800: #323649;
// $gray-900:;

$green-500: #8ab125;

$teal-300: #bdc9b6;
$teal-600: #becab7;

$orange-300: #e7c8b0;
$orange-400: #bc7968;

$orange-900: #323649;

$red-100: #fffbfb;
$danger: #d02b2b;

$primary: $orange-400;
$secondary: $gray-700;

$font-size-base: 0.875rem; // (16 / 14)

$h1-font-size: px-to-rem(72);
$h2-font-size: px-to-rem(60);
$h3-font-size: px-to-rem(48);
$h4-font-size: px-to-rem(36);
$h5-font-size: px-to-rem(28);
$h6-font-size: px-to-rem(20);

$breadcrumb-divider: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='gray'/%3E%3C/svg%3E");
$breadcrumb-divider-color: rgba(0, 0, 0, 0.4);
$breadcrumb-active-color: black;
$breadcrumb-padding-y: 10px;
$breadcrumb-margin-bottom: 0px;

$navbar-toggler-padding-x: 2px;
$navbar-toggler-padding-y: 2px;

$pagination-border-width: 0;
$pagination-active-color: black;
$pagination-active-bg: transparent;
$pagination-color: $gray-800;
$pagination-focus-color: white;
$pagination-focus-bg: $teal-600;
$pagination-hover-color: white;
$pagination-hover-bg: $teal-600;

$nav-pills-border-radius: 0;
$nav-pills-link-active-color: black;
$nav-pills-link-active-bg: transparent;

$badge-font-weight: normal;
$badge-padding-y: calc((25px - 12px) / 2);
$badge-padding-x: 9px;

$nav-tabs-border-color: $gray-250;
$nav-tabs-link-active-border-color: $gray-250 $gray-250 white;
$nav-link-color: $gray-600;
$nav-pills-link-active-color: black;

$dropdown-padding-y: 0;
$dropdown-item-padding-y: 20px;
$dropdown-item-padding-x: 18px;

$carousel-control-color: $primary;
